<template>
	<div id="Shop">
		<div id="Shop-box">
			Shop
		</div>
	</div>
</template>

<script>
	export default{
		name:'Shop',
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="scss">
	#Shop-box{
		background: red;
	}
</style>
